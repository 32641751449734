import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { AchievementResource } from '@rallycry/api-suite-typescript'
import { useFormikContext } from 'formik'
import { isArray } from 'lodash-es'
import { useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { LabeledFieldHeader } from '@/components/organisms/form/LabeledField'
import { EntityAutocomplete } from '@/components/molecules/input/EntityAutocomplete'
import { useAchievementDocument } from '@/entity/achievements/useAchievementDocument'
import { CreateEditAchievementsForm } from '@/components/pages/Administration/components/achievements/CreateEditAchievementsForm'

export const FormikAchievementSearch = ({
  name,
  label,
  allowPlaceholderCreation
}: {
  name: string
  label: React.ReactNode
  allowPlaceholderCreation?: boolean
}) => {
  const { t } = useRcTranslation()
  const { values, setFieldValue } = useFormikContext<any>()
  const [query, setQuery] = useState<string>('')
  const [debouncedQuery] = useDebounce(query, 300)
  const {
    achievements,
    query: { isValidating, size, setSize }
  } = useAchievementDocument({
    request: {
      q: debouncedQuery
    }
  })

  const selected = useMemo(() => {
    return [values[name]]
  }, [values, name])

  return (
    <Box>
      <LabeledFieldHeader label={label} />
      <EntityAutocomplete
        isValidating={isValidating}
        options={achievements}
        selected={selected}
        enableReinitialize
        placeholder={t('search-placeholder')}
        onSearch={q => setQuery(q)}
        onLoadOptions={() => setSize(size + 1)}
        onChange={async updated => {
          const achievement = isArray(updated) ? undefined : updated
          setFieldValue(name, achievement?.id)
        }}
      />
      {allowPlaceholderCreation ? (
        <CreateAchievementInline
          onComplete={async achievement => {
            setQuery(achievement?.name || '')
            setFieldValue(name, achievement?.id)
          }}
        />
      ) : null}
    </Box>
  )
}

const CreateAchievementInline = ({
  onComplete
}: {
  onComplete: (achievement: AchievementResource) => Promise<void>
}) => {
  return (
    <ModalTrigger
      activation={handleOpen => (
        <Link
          onClick={ev => {
            ev.stopPropagation()
            ev.preventDefault()
            handleOpen()
          }}
          sx={{ cursor: 'pointer' }}
        >
          <Typography variant='subtitle2' pt={2}>
            Create new achievement
          </Typography>
        </Link>
      )}
    >
      {({ handleClose }) => (
        <CreateEditAchievementsForm
          onComplete={async achievement => {
            handleClose()
            onComplete(achievement as AchievementResource)
          }}
        />
      )}
    </ModalTrigger>
  )
}
