'use client'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { AcceptableSubmit } from '@/components/molecules/input/AcceptableSubmit'
import { useApiError } from '@/core/hooks/useApiError'
import { FormikAchievementSearch } from '@/components/molecules/input/FormikAchievementSearch'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useOrganization } from '@/entity/organization/useOrganization'

type FormModel = {
  selectedAchievement: number
}

export const GrantAchievement = ({
  onGrantAchievement,
  onComplete
}: {
  onGrantAchievement: (values: FormModel) => any
  onComplete: () => any
}) => {
  const { isOrgSuperAdmin } = useOrganization()
  const { handle } = useApiError()

  const handleSubmit = async (
    values: FormModel,
    helpers: FormikHelpers<FormModel>
  ) => {
    try {
      await onGrantAchievement(values)
      onComplete()
    } catch (e) {
      handle(e, { values, helpers })
    }
  }

  const validation = Yup.object<FormModel>({
    selectedAchievement: Yup.number().required().min(1)
  })

  return (
    <Formik
      initialValues={{ selectedAchievement: 0 }}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <FormikAchievementSearch
            name='selectedAchievement'
            label='Achievement'
            allowPlaceholderCreation={isOrgSuperAdmin}
          />
          <ModalConfiguration
            title={<RcTrans i18nKey='shared.grant-achievement' />}
          >
            <AcceptableSubmit />
          </ModalConfiguration>
        </Form>
      )}
    </Formik>
  )
}
