import { useRouter } from 'next/navigation'
import { useEffect, useLayoutEffect } from 'react'
import { ComponentDynamicRedirect } from '../../../../../models/types'
import { RcLoader } from '@/components/atoms/RcLoader'

export const Redirect = ({
  path,
  replace,
  skipScroll,
  height
}: Partial<ComponentDynamicRedirect> & {
  replace?: boolean
  skipScroll?: boolean
  height?: string | number | undefined
}) => {
  const router = useRouter()
  useLayoutEffect(() => {
    if (!path) return
    if (replace) {
      router.replace(path, { scroll: !skipScroll })
    } else {
      router.push(path, { scroll: !skipScroll })
    }
  }, [path, replace, router, skipScroll])

  return <RcLoader height={height} />
}
