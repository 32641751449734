import Grid from '@mui/material/Grid'
import { AchievementResource } from '@rallycry/api-suite-typescript/dist/models/AchievementResource'
import { Form, Formik, FormikHelpers } from 'formik'
import { TextField } from 'formik-mui'
import dynamic from 'next/dynamic'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import {
  AchievementCreateCommand,
  AchievementUpdateCommand
} from '@rallycry/api-suite-typescript'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { LabeledField } from '@/components/organisms/form/LabeledField'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { usePage } from '@/components/providers/site/PageProvider'
import { useApiError } from '@/core/hooks/useApiError'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { useAchievements } from '@/entity/achievements/useAchievements'
import { useOrganization } from '@/entity/organization/useOrganization'

const RcFileInput = dynamic(
  async () =>
    (await import('@/components/molecules/input/RcFileInput')).RcFileInput,
  { ssr: false, loading: () => <></> }
)

interface FormModel {
  name: string
  disambiguatingDescription: string
  image: string
  icon: string
  organization: number
}

export const CreateEditAchievementsForm = ({
  initial,
  shouldClone,
  onComplete
}: {
  initial?: AchievementResource
  shouldClone?: boolean
  onComplete: (achievement: AchievementUpdateCommand) => void
}) => {
  const { open } = usePage()
  const { handle } = useApiError()
  const { create, update } = useAchievements()
  const { t } = useRcTranslation()
  const { organization } = useOrganization()
  const organizationId = organization?.id

  const submit = async (
    values: FormModel,
    helpers: FormikHelpers<FormModel>
  ) => {
    const { name, image, icon, disambiguatingDescription } = values

    try {
      const data = {
        organization: organizationId,
        name,
        image,
        icon,
        description: disambiguatingDescription
      }

      const res =
        initial && !shouldClone
          ? await update(initial.id!, data)
          : await create(data)

      helpers.resetForm()
      onComplete(res as AchievementUpdateCommand | AchievementCreateCommand)
      open(null, <RcTrans i18nKey='shared.success' />, 6000)
    } catch (error) {
      await handle(error, { values, helpers })
      open(null, `Unable to submit: ${error}`, 6000)
    }

    return Promise.resolve()
  }

  const validation = Yup.object<FormModel>({
    name: Yup.string().required(t('error.field-required')),
    disambiguatingDescription: Yup.string(),
    image: Yup.string().url(t('error.invalid-url')),
    icon: Yup.string()
      .url(t('error.invalid-url'))
      .required(t('error.field-required')),
    organization: Yup.number().required(t('error.field-required'))
  })

  return (
    <Formik
      initialValues={{
        name: initial?.name || '',
        disambiguatingDescription: initial?.disambiguatingDescription || '',
        image: initial?.image || '',
        icon: initial?.icon || '',
        organization: organizationId || 0
      }}
      validationSchema={validation}
      onSubmit={submit}
    >
      {({ values, setFieldValue, submitForm }) => (
        <Form id='manage-achievements-form'>
          <Grid container direction='column' spacing={3}>
            <Grid item>
              <LabeledField
                component={TextField}
                name='name'
                type='text'
                variant='outlined'
                fullWidth
                label={<RcTrans i18nKey='shared.name' />}
              />
            </Grid>
            <Grid item>
              <LabeledField
                component={TextField}
                name='disambiguatingDescription'
                type='text'
                variant='outlined'
                fullWidth
                multiline
                label={<RcTrans i18nKey='shared.type' />}
              />
            </Grid>
            <Grid item>
              <LabeledField
                sx={{ mb: 2 }}
                component={TextField}
                name='image'
                type='text'
                variant='outlined'
                fullWidth
                label={<RcTrans i18nKey='shared.image' />}
              />
              <RcFileInput
                saveAs={`achievement-banner-${initial?.id}`}
                source={values.image}
                onComplete={async url => setFieldValue('image', url)}
                onClear={async () => setFieldValue('image', '')}
              />
            </Grid>
            <Grid item>
              <LabeledField
                sx={{ mb: 2 }}
                component={TextField}
                name='icon'
                type='text'
                variant='outlined'
                fullWidth
                label={<RcTrans i18nKey='shared.icon' />}
              />
              <RcFileInput
                saveAs={`achievement-icon-${initial?.id || uuidv4()}`}
                source={values.icon}
                onComplete={async url => setFieldValue('icon', url)}
                onClear={async () => setFieldValue('icon', '')}
              />
            </Grid>
            <ModalConfiguration
              title={<RcTrans i18nKey='shared.create-achievement' />}
            >
              <RcButton
                fullWidth
                type='submit'
                form='manage-achievements-form'
                variant='contained'
              >
                <RcTrans i18nKey='shared.submit' />
              </RcButton>
            </ModalConfiguration>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
