import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import React, { PropsWithChildren } from 'react'
import { LabeledFieldHeader } from '../form/LabeledField'

export interface PageFormProps {
  title?: React.ReactNode
  info?: React.ReactNode
  description?: React.ReactNode
  sideChild?: boolean
}

export const PageForm: React.FC<PropsWithChildren<PageFormProps>> = ({
  children,
  title,
  info,
  description,
  sideChild
}) => {
  return (
    <Box width='100%'>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={3}
        mb={2}
      >
        <LabeledFieldHeader
          label={title}
          description={description}
          info={info}
        />
        {sideChild ? children : null}
      </Stack>
      {!sideChild ? children : null}
    </Box>
  )
}
