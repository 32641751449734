import { AchievementCreateCommand } from '@rallycry/api-suite-typescript/dist/models/AchievementCreateCommand'
import { AchievementControllerApi } from '@rallycry/api-suite-typescript/dist/apis/AchievementControllerApi'
import { AchievementUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/AchievementUpdateCommand'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useAchievements = (options?: EntityOptions) => {
  const { ctrl } = useController(AchievementControllerApi)
  const entity = useQueryEntity({
    key: 'useAchievements',
    paused: !options?.request,
    ...options,
    request: { ...options?.request, q: options?.request?.q || undefined },
    read: req => ctrl().queryAchievements(req)
  })

  const create = async (cmd: AchievementCreateCommand) => {
    const res = await ctrl().createAchievement({
      AchievementCreateCommand: cmd
    })
    await entity.query.mutate()
    return res
  }

  const update = async (id: number, cmd: AchievementUpdateCommand) => {
    const res = await ctrl().updateAchievement({
      id,
      AchievementUpdateCommand: cmd
    })
    await entity.query.mutate()
    return res
  }

  const remove = async (id: number) => {
    await ctrl().removeAchievement({ id })
    await entity.query.mutate()
  }

  return { ...entity, achievements: entity.flat, create, update, remove }
}
