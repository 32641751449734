import FormHelperText from '@mui/material/FormHelperText'
import { useFormikContext } from 'formik'

export const RcErrorDisplay = ({
  name,
  skipTouched
}: {
  name: string
  skipTouched?: boolean
}) => {
  const { getFieldMeta } = useFormikContext()

  return (getFieldMeta(name.toString()).touched || skipTouched) &&
    getFieldMeta(name.toString()).error ? (
    <FormHelperText error sx={{ ml: 4 }}>
      {getFieldMeta(name.toString()).error}
    </FormHelperText>
  ) : null
}
