import Button from '@mui/material/Button'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { RcTrans } from '@/components/atoms/RcTrans'
import { usePage } from '@/components/providers/site/PageProvider'

export const AcceptableSubmit = () => {
  const { accepted, resetAccepted } = usePage()
  const { submitForm, values, isSubmitting, isValid } = useFormikContext()
  const [prev, setPrev] = useState(values)

  // Reset accepted codes when values change
  useEffect(() => {
    if (accepted?.() && JSON.stringify(values) !== JSON.stringify(prev)) {
      resetAccepted()
    }
  }, [values, accepted, resetAccepted, prev])

  return (
    <Button
      onClick={() => {
        setPrev(values)
        submitForm()
      }}
      disabled={isSubmitting || !isValid}
      variant='contained'
      fullWidth
      color={accepted?.() ? 'warning' : 'primary'}
    >
      {isSubmitting ? (
        <RcTrans i18nKey='shared.submitting' />
      ) : accepted?.() ? (
        <RcTrans i18nKey='shared.resubmit' />
      ) : (
        <RcTrans i18nKey='shared.submit' />
      )}
    </Button>
  )
}
