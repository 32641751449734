'use client'
import { BoxProps } from '@mui/material'
import Box from '@mui/material/Box'

export const InvertedRadiusBox = (
  props: BoxProps & { borderTopLeftRadius?: number }
) => {
  const width = props.width || 38
  const height = props.height || 32

  return (
    <Box position='relative'>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: width,
          height: height,
          backgroundColor: 'primary.main',
          borderBottomRightRadius: 10,
          borderTopLeftRadius: props.borderTopLeftRadius || 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {props.children}
      </Box>
      <Box
        sx={theme => ({
          position: 'absolute',
          top: height,
          left: 0,
          backgroundColor: 'transparent',
          borderTopLeftRadius: 6,
          boxShadow: `0 -20px 0 0 ${theme.palette.primary.main}`,
          height: 50,
          width: 10
        })}
      />
      <Box
        sx={theme => ({
          position: 'absolute',
          top: 0,
          left: width,
          backgroundColor: 'transparent',
          borderTopLeftRadius: 6,
          boxShadow: `-20px 0 0 0 ${theme.palette.primary.main}`,
          height: 10,
          width: 50
        })}
      />
    </Box>
  )
}
