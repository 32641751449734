import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/styles'
import { ReactNode } from 'react'
import { CommunityAchievedResource } from '@rallycry/api-suite-typescript/dist/models/CommunityAchievedResource'
import { UserAchievedResource } from '@rallycry/api-suite-typescript/dist/models/UserAchievedResource'
import { AchievementResource } from '@rallycry/api-suite-typescript/dist/models/AchievementResource'
import Card from '@mui/material/Card'
import Tooltip from '@mui/material/Tooltip'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { InvertedRadiusBox } from '@/components/molecules/text/InvertedRadiusBox'
import { EllipsisBox } from '@/components/molecules/text/EllipsisBox'

export const AchievementCard = ({
  id,
  resource,
  achievement,
  actions
}: {
  id: number
  actions?: ReactNode
  src?: string
  name?: string
  description?: string
  handlePinned?: ({ id }: { id: number }) => void
  unHandlePinned?: ({ id }: { id: number }) => void
  resource?: CommunityAchievedResource | UserAchievedResource
  achievement?: AchievementResource
}) => {
  const theme = useTheme()
  return (
    <Card
      elevation={3}
      sx={{
        p: 2,
        position: 'relative',
        overflow: 'visible',
        borderRadius: '10px'
      }}
    >
      <Stack
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <AvatarText src={achievement?.image} size='xl' variant='rounded'>
          <Stack spacing={1} maxWidth='220px'>
            <Typography variant='h3'>{achievement?.name}</Typography>
            <EllipsisBox lineClamp={2}>
              <Tooltip title={achievement?.disambiguatingDescription}>
                <Typography variant='subtitle2' color='text.secondary'>
                  {achievement?.disambiguatingDescription}
                </Typography>
              </Tooltip>
            </EllipsisBox>
          </Stack>
        </AvatarText>
        {actions}
      </Stack>
      {resource?.pinned ? (
        <Box
          sx={{
            zIndex: 1,
            backgroundColor: theme.palette.secondary.main,
            position: 'absolute',
            top: 0,
            right: 0,
            transform: 'scaleX(-1)'
          }}
        >
          <InvertedRadiusBox pb={0} borderTopLeftRadius={10}>
            <RcIconButton
              disableRipple
              sx={{ zIndex: 1 }}
              icon={['fal', 'thumbtack']}
              size='small'
            />
          </InvertedRadiusBox>
        </Box>
      ) : null}
    </Card>
  )
}
