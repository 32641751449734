import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { PropsWithChildren } from 'react'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'

export const NoResults = (props: PropsWithChildren) => {
  return (
    <Stack
      height='100%'
      direction='column'
      spacing={5}
      alignItems='center'
      justifyContent='center'
      py={15}
    >
      <RcIcon icon={['fad', 'cat-space']} size='10x' color='primary' />
      <Typography align='center' variant='h4'>
        {props.children ? (
          props.children
        ) : (
          <RcTrans i18nKey='shared.search-no-results' />
        )}
      </Typography>
    </Stack>
  )
}
