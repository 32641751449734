'use client'

import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import { Form, Formik, FormikHelpers } from 'formik'
import { Select } from 'formik-mui'
import React, { Children, ReactNode } from 'react'
import { RcCompletable } from './RcInlineEdit'
import {
  LabeledField,
  LabeledFieldHeader
} from '@/components/organisms/form/LabeledField'
import { RcErrorDisplay } from '@/components/atoms/RcErrorDisplay'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useIsMobileDevice } from '@/core/hooks/useMediaQueries'
import { useApiError } from '@/core/hooks/useApiError'

export interface RcSelectProps<T> extends RcCompletable {
  property: keyof T
  source: T | undefined
  update: (value: T) => Promise<any>
  label?: React.ReactNode
  description?: React.ReactNode
  disabled?: boolean
  allowNone?: boolean
  multiple?: boolean
  renderValue?: (it: any) => string
}

// Formik wrapped select that allows quick edits to single fields with limited #s of options
export const RcSelect = <T extends Record<string, any>>({
  property,
  source,
  update,
  label,
  description,
  disabled = false,
  allowNone,
  multiple,
  renderValue,
  children,
  onComplete
}: RcSelectProps<T> & { children?: ReactNode }) => {
  const { handle } = useApiError()
  const isMobileDevice = useIsMobileDevice()

  const submit = async (values: any, helpers: FormikHelpers<any>) => {
    // allow clearing for 'none' value
    if (values[property] === 'unset') values[property] = null

    try {
      await update(values)
    } catch (error) {
      handle(error, { values, helpers })
    } finally {
      onComplete?.()
    }

    return Promise.resolve()
  }

  if (!source || !children)
    return (
      <>
        <LabeledFieldHeader label={label} description={description} />
        <Skeleton height={40} />
      </>
    )

  return (
    <Formik
      enableReinitialize
      initialValues={{
        [property]:
          source[property] !== undefined && source[property] !== null
            ? source[property]
            : 'unset'
      }}
      onSubmit={submit}
    >
      {({ submitForm, isSubmitting }) => (
        <Form id='form'>
          <FormControl>
            <LabeledField
              component={Select}
              native={isMobileDevice}
              name={property}
              label={label}
              description={description}
              inputProps={{ onChange: () => submitForm() }}
              disabled={disabled || isSubmitting}
              variant='outlined'
              renderValue={renderValue}
              multiple={multiple}
            >
              {allowNone ? (
                <MenuItem
                  value={'unset'}
                  component={isMobileDevice ? 'option' : MenuItem}
                >
                  <RcTrans i18nKey='shared.none-selected' />
                </MenuItem>
              ) : null}
              {Children.toArray(children).map((it: any) =>
                isMobileDevice ? <option key={it.key} {...it.props} /> : it
              )}
            </LabeledField>
            <RcErrorDisplay name={property as string} />
          </FormControl>
        </Form>
      )}
    </Formik>
  )
}
