import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FormikProvider } from 'formik'
import { ReactNode, useState } from 'react'
import { RcIconButton } from '../interactive/RcIconButton'
import { RcCompletable } from './RcInlineEdit'
import { LabeledSwitch } from '@/components/organisms/form/LabeledSwitch'

export interface RcToggleProps<T> extends RcCompletable {
  property: keyof T
  source: T | undefined
  update: (value: T) => Promise<any>
  icon?: IconProp
  inverse?: boolean
  direction?: 'row' | 'row-reverse'
  label?: ReactNode
  disabled?: boolean
}

export const RcToggle = <T extends Record<string, any>>({
  property,
  source,
  update,
  icon,
  inverse,
  direction,
  label,
  disabled
}: RcToggleProps<T> & { children?: ReactNode }) => {
  const selected = inverse ? !source?.[property] : !!source?.[property]
  const [state, setState] = useState({
    isUpdating: false
  })

  const onSwitched = async () => {
    const res = {} as T
    res[property] = (inverse ? selected : !selected) as any
    setState(s => ({ ...s, isUpdating: true }))
    try {
      await update(res)
    } finally {
      setState(s => ({ ...s, isUpdating: false }))
    }
  }

  return icon ? (
    <RcIconButton
      icon={selected ? (['fal', 'check'] as IconProp) : icon}
      disabled={state.isUpdating || disabled}
      onClick={onSwitched}
    />
  ) : (
    <FormikProvider value={{} as any}>
      <LabeledSwitch
        name={property?.toString()}
        color='default'
        checked={selected}
        disabled={state.isUpdating || disabled}
        onChange={onSwitched}
        direction={direction}
        label={label}
      />
    </FormikProvider>
  )
}
