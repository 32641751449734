import {
  AchievementDocumentControllerApi,
  SearchAchievementsRequest
} from '@rallycry/api-suite-typescript/dist/apis/AchievementDocumentControllerApi'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useController } from '@/core/hooks/useSWRApi'

export const useAchievementDocument = (
  options?: EntityOptions<SearchAchievementsRequest>
) => {
  const { ctrl } = useController(AchievementDocumentControllerApi)
  const entity = useQueryEntity({
    key: 'useAchievementDocument',
    paused: !options?.request,
    persist: true,
    ...options,
    request: { ...options?.request, q: options?.request?.q || undefined },
    read: req =>
      ctrl({
        expectedStatuses: [403, 404, 418],
        fallbackResponse: []
      }).searchAchievements(req)
  })

  return { ...entity, achievements: entity.flat }
}
