import { CompetitionTicketControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionTicketControllerApi'
import { TicketUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/TicketUpdateCommand'
import { useCallback } from 'react'
import { useOrganization } from '../organization/useOrganization'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useUserAccount } from '../user/useUserAccount'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export const useCompetitionTicket = (options?: EntityOptions) => {
  const competitionId = useParsedParam('competitionId')
  const { account } = useUserAccount()
  const { organization } = useOrganization()
  const { ctrl } = useController(CompetitionTicketControllerApi)

  const entity = useReadEntity({
    key: 'useCompetitionTicket',
    expand: '_links',
    paused: !options?.idOrKey,
    ...options,
    read: req => ctrl().readCompetitionTicket(req)
  })

  const create = useCallback(
    async (description: string) => {
      const res = await ctrl({
        expectedStatuses: [422]
      }).createCompetitionTicket({
        competitionId,
        CompetitionTicketCreateCommand: {
          description,
          user: account?.id!
        }
      })
      return res
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, competitionId, organization /*ctrl*/]
  )

  const update = async (id: number, cmd: TicketUpdateCommand) => {
    const res = await ctrl().updateCompetitionTicket({
      id,
      TicketUpdateCommand: cmd
    })
    await entity.read.mutate(res, false)
  }

  const close = async (id: number) => {
    const res = await ctrl().closeCompetitionTicket({ id })
    await entity.read.mutate(res, false)
  }

  return { ...entity, ticket: entity.read.data, create, update, close }
}
