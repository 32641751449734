import Alert from '@mui/material/Alert'
import { AlertProps } from '@mui/material/Alert'
import { isFunction } from 'lodash-es'

export const RcAlertItem = ({ issue }: { issue: AlertProps }) => {
  return (
    <Alert
      variant={'outlined'}
      elevation={0}
      {...issue}
      onClick={ev => {
        issue?.onClick?.(ev)
      }}
      sx={theme => ({
        py: 3,
        pr: 1,
        pl: 3,
        minHeight: 48,
        cursor: issue?.onClick ? 'pointer' : 'inherit',
        '& .MuiLink-root': {
          color: 'inherit',
          textDecoration: 'underline'
        },
        '& .MuiAlert-action': {
          marginRight: 3,
          '& .MuiButton-root': {
            backgroundColor:
              issue.severity === 'success'
                ? theme.palette.success[theme.palette.mode]
                : issue.severity === 'warning'
                ? theme.palette.warning[theme.palette.mode]
                : issue.severity === 'info'
                ? theme.palette.info[theme.palette.mode]
                : issue.severity === 'error'
                ? theme.palette.error[theme.palette.mode]
                : theme.palette.primary[theme.palette.mode]
          }
        },
        ...((isFunction(issue.sx) ? issue.sx?.(theme) : issue.sx) as any)
      })}
    />
  )
}
