import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import React, { useRef, useState } from 'react'
import { RcIconButton, RcIconButtonProps } from './RcIconButton'

export interface RcDropdownOption {
  label: React.ReactNode
  route?: string
  onClick?: (e: React.MouseEvent) => void
}

export interface RcDropdownProps extends RcIconButtonProps {
  icon: IconProp
  options?: Array<RcDropdownOption>
}

export const RcDropdown: React.FC<RcDropdownProps> = ({
  icon,
  options,
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    options?.[index]?.onClick?.(event)
    if (options?.[index]?.route) {
      window.open(options?.[index]?.route)
    }
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Box ref={anchorRef}>
        <RcIconButton onClick={handleToggle} icon={icon} {...rest} />
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement='bottom-end'
        style={{ zIndex: 999 }}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper variant='outlined'>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu'>
                  {options?.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
